.main {
	width: 100%;
}
.main .section {
	position: relative;
	z-index: 1;
	width: 100%;
	display: block;
}
.section .staticContent {
	width: 100%;
}
.staticContent img {
	width: 100%;
	object-fit: contain;
}
.section01 {
	margin-top: 0;
	margin-bottom: 12vw;
}
.sectionParallax {
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.sectionParallax img {
	visibility: hidden;
}
.sectionContact,
.sectionTedTalk {
	height: 100dvh;
}
.sectionContact {
	display: flex;
	align-items: center;
	justify-content: center;
}
.sectionTedTalk .responsiveContent {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.sectionContact .responsiveContent {
	width: 60%;
	max-width: 720px;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
}
.sectionContact .responsiveContent .contactHeading {
	margin-bottom: 21px;
	font-size: 1.5em;
	margin-block-start: 1rem;
	margin-block-end: 1rem;
	font-weight: bold;
}
.sectionContact .responsiveContent form {
	flex-wrap: wrap;
	display: flex;
	align-items: stretch;
	flex-direction: row;
	width: 100%;
	margin-bottom: 18px;
	justify-content: center;
}
.sectionContact .responsiveContent form label {
	position: absolute;
	top: -10000em;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
.sectionContact label {
	font-weight: 600;
}
.sectionContact .responsiveContent form .form_section {
	width: 100%;
	padding-right: calc(10px / 2);
	padding-left: calc(10px / 2);
	box-sizing: border-box;
	margin-bottom: 15px;
}
.sectionContact .responsiveContent form .form_section_half {
	width: 50%;
	padding-right: calc(10px / 2);
	padding-left: calc(10px / 2);
	margin-bottom: 15px;
	box-sizing: border-box;
}
.sectionContact .responsiveContent form .form_section button,
.sectionContact .responsiveContent form .form_section input,
.sectionContact .responsiveContent form .form_section_half input {
	width: 100%;
}
.sectionContact .responsiveContent form .form_section button {
	background-color: #000;
	color: #fff;
	border: 0;
	box-sizing: border-box;
	font-size: 17px;
	padding: 12px 24px;
	font-weight: 600;
	cursor: pointer;
	text-align: center;
}

.sectionContact .responsiveContent form textarea {
	height: 128px;
	max-width: 100%;
	min-width: 100%;
}
.sectionContact .socials_footer .socials_footer_heading {
	margin-bottom: 16px;
	display: block;
	font-size: 1.5em;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}
.sectionContact .socials_footer .social_icon_list {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1em;
	margin-bottom: 24px;
}
.sectionContact .socials_footer .social_icon_list .social_icon_instagram,
.sectionContact .socials_footer .social_icon_list .social_icon_twitter,
.sectionContact .socials_footer .social_icon_list .social_icon_linkedin {
	width: 2em;
	object-fit: contain;
}
.legal_footer {
	margin: 0 auto;
}
.legal_footer .legal_footer_heading {
	font-size: 14px;
	font-weight: 300;
	align-self: flex-end;
	text-align: center;
	margin-bottom: 12px;
}
.sectionParallax.section03 {
	background-image: url(/homepage-contents/images/block-3/Website_Block3_parallax.png);
}
.sectionParallax.section05 {
	background-image: url(/homepage-contents/images/block-5/Websit_Block5_parallax.png);
}
.sectionParallax.section07 {
	background-image: url(/homepage-contents/images/block-7/Website_Block7_parallax.png);
}
.sectionParallax.section09 {
	background-image: url(/homepage-contents/images/block-9/Website_Block9_parallax.png);
}
.tedtalk_video_container {
	width: 70%;
}
@media only screen and (min-width: 1441px) {
	.tedtalk_video_container {
		width: 70%;
	}
}
@media only screen and (max-width: 1068px) {
	.tedtalk_video_container {
		width: 80%;
	}
	.sectionContact .responsiveContent {
		width: 80dvw;
	}
	.sectionContact .responsiveContent form .form_section-half {
		width: 100%;
	}
}
@media only screen and (max-width: 734px) {
	.tedtalk_video_container {
		width: 100%;
	}
}
@media (pointer: none), (pointer: coarse) {
	.sectionParallax {
		background-attachment: initial;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}
